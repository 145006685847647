import * as React from 'react';
import { LinkBarProps } from '../../../LinkBar.types';
import styles from '../../style/ResponsiveLinkBar.scss';
import LinkBar from '../../LinkBar';

const ResponsiveLinkBar: React.FC<Omit<LinkBarProps, 'styles'>> = props => {
  return <LinkBar {...props} styles={styles} />;
};

export default ResponsiveLinkBar;
